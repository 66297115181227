<template>
  <v-card class="elevation-x mb-7">
    <v-list-item inactive two-line>
      <v-list-item-avatar :width="$vuetify.breakpoint.mdAndDown ? '50px' : '80px'" :height="$vuetify.breakpoint.mdAndDown ? '50px' : '80px'" class="mt-0">
        <user-avatar :size="$vuetify.breakpoint.mdAndDown ? '50px' : '80px'" :user="professional" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="text-md-h4 text-h6 font-alt font-weight-thin">
          {{ professional.full_name || 'Non renseigné' }}
        </v-list-item-title>
        <v-list-item-subtitle class="secondary--text text--darken-1 font-weight-thin font-alt text-md-h6 text-subtitle-2">
          {{ professional.job.name || 'Non renseigné' }}
        </v-list-item-subtitle>
        <div class="actions-list">
          <feed-subscribe-button v-if="feedList && professional.uid !== $auth.user.sub" :list="feedList" />
          <v-chip v-if="$auth.granted('role_professionals')" small class="my-2 pl-0 mr-2 font-alt" color="primary" :disabled="!professional.phone" @click.stop="call(professional.phone)">
            <font-awesome-layers class="fa-lg mx-1">
              <font-awesome-icon icon="circle" class="primary--text text--lighten-2" />
              <font-awesome-icon :icon="['fad', 'phone-alt']" transform="shrink-7" class="white--text" />
            </font-awesome-layers>
            Appeler
          </v-chip>
          <v-chip v-if="$auth.granted('role_professionals')" small class="my-2 pl-0 mr-2 font-alt" color="primary" :disabled="!professional.email" @click.stop="sendmail(professional.email)">
            <font-awesome-layers class="fa-lg mx-1">
              <font-awesome-icon icon="circle" class="primary--text text--lighten-2" />
              <font-awesome-icon :icon="['fad', 'at']" transform="shrink-7" class="white--text" />
            </font-awesome-layers>
            Email
          </v-chip>
        </div>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-row class="ma-0">
      <v-col cols="12" md="6" class="py-2">
        <v-list-item-subtitle class="pl-1 font-alt">Email :</v-list-item-subtitle>
        <div class="caption pl-1">
          {{ professional.email || 'Non renseigné' }}
        </div>
      </v-col>
      <v-col cols="12" md="6" class="py-2">
        <v-list-item-subtitle class="pl-1 font-alt">Téléphone :</v-list-item-subtitle>
        <div class="caption pl-1">
          {{ professional.phone || 'Non renseigné' }}
        </div>
      </v-col>
      <v-col cols="12" class="py-2">
        <v-list-item-subtitle class="pl-1 font-alt">Adresse :</v-list-item-subtitle>
        <div v-if="professional.main_address" class="caption pl-1">
          <span class="mr-1">{{ professional.main_address.address1 || 'Non renseigné' }},</span>
          <span class="mr-1">{{ professional.main_address.zip_code.name || '' }}</span>
          <span>{{ professional.main_address.city.name || '' }}</span>
        </div>
        <div v-else class="caption pl-1">
          Non renseigné
        </div>
      </v-col>
      <v-col cols="12" class="py-2">
        <v-list-item-subtitle class="pl-1 font-alt mb-2">Spécialité(s) :</v-list-item-subtitle>
        <div v-if="professional.specialties.length">
          <template v-for="specialty in professional.specialties">
            <v-chip :key="specialty.uid" small outlined class="mr-1 mb-1">
              {{ specialty.name }}
            </v-chip>
          </template>
        </div>
        <v-chip v-else outlined small>
          Non renseigné
        </v-chip>
      </v-col>
      <v-col cols="12" class="py-2">
        <v-list-item-subtitle class="pl-1 font-alt mb-2">Prise(s) en charge :</v-list-item-subtitle>
        <div v-if="professional.skills.length">
          <template v-for="skill in professional.skills">
            <v-chip :key="skill.uid" outlined small class="mr-1 mb-1">
              {{ skill.name }}
            </v-chip>
          </template>
        </div>
        <v-chip v-else outlined small>
          Non renseigné
        </v-chip>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import contactMethods from '@/mixins/contactMethods'
  import FeedSubscribeButton from '@/modules/feeds/components/FeedSubscribeButton'
  import {FeedList} from '@/modules/feeds/models'
  import UserAvatar from '@/modules/core/layout/UserAvatar'

  export default {
    name: 'ProfessionalHeaderContent',
    components: {UserAvatar, FeedSubscribeButton},
    mixins: [contactMethods],
    props: {
      professional: {
        type: Object,
        required: false
      },
      feedList: FeedList
    }
  }
</script>

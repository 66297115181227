<template>
  <v-chip small class="my-2 pl-1 mr-2 font-alt" v-bind="$attrs" :loading="loading" :disabled="loading"
          :color="feed.subscribed ? 'grey lighten-2' : 'primary'"
          v-on="$listeners"
          @click="toggle"
  >
    <font-awesome-layers class="fa-lg mr-2">
      <font-awesome-icon icon="circle" class="primary--text text--lighten-2" />
      <font-awesome-icon :icon="['fal', feed.subscribed ? 'minus' : 'plus']" transform="shrink-6"
                         class="white--text"
      />
    </font-awesome-layers>
    {{ feed.subscribed ? 'Ne plus suivre' : 'Suivre' }}
  </v-chip>
</template>

<script>
  import {Feed, FeedList} from '@/models'
  import {mapActions, mapMutations, mapState} from 'vuex'

  export default {
    name: 'FeedSubscribeButton',
    props: {
      list: FeedList
    },
    data() {
      return {
        loading: true
      }
    },
    computed: {
      ...mapState('feeds', ['userFeedList']),
      feed() {
        return Feed.get(this.list.personal_feed)
      }
    },
    async created() {
      await this.fetchUserFeedList()
      this.loading = false
    },
    methods: {
      ...mapMutations('feeds', ['addSubscription', 'removeSubscription']),
      ...mapActions('feeds', ['fetchUserFeedList', 'subscribe', 'unsubscribe']),
      async toggle() {
        try {
          this.loading = true

          if(this.feed.subscribed) {
            await this.unsubscribe(this.list)
          }
          else {
            await this.subscribe(this.list)
          }
        } catch (e) {
          this.$handleApiError(e)
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>

<template>
  <v-container v-if="!loading" fluid>
    <v-row>
      <v-col cols="12" xl="3" lg="4" :class="$vuetify.breakpoint.mdAndDown ? 'pa-0' : ''">
        <professional-side-bar-professional v-if="$auth.granted('role_professionals')" :professional="professional" />
        <professional-side-bar-patient v-else :professional="professional" />
      </v-col>
      <v-col cols="12" xl="6" lg="8" :class="$vuetify.breakpoint.mdAndDown ? 'px-1' : ''">
        <professional-header-content :professional="professional" :feed-list="feedList" />
        <vue-page-transition name="fade-in-down">
          <router-view :key="$route.fullPath" :professional="professional" />
        </vue-page-transition>
      </v-col>
    </v-row>
  </v-container>
  <page-loader v-else />
</template>

<script>
  import ProfessionalSideBarProfessional from '@/modules/professionals/components/professionals/ProfessionalSideBar'
  import ProfessionalSideBarPatient from '@/modules/professionals/components/patients/ProfessionalSideBar'
  import ProfessionalHeaderContent from '@/modules/professionals/components/ProfessionalHeaderContent'
  import PageLoader from '@/modules/core/layout/PageLoader'
  import {FeedList, Professional} from '@/models'

  export default {
    name: 'ProfessionalSection',
    components: {ProfessionalSideBarProfessional,ProfessionalSideBarPatient, PageLoader, ProfessionalHeaderContent},
    data() {
      return {
        loading: true
      }
    },
    computed: {
      professional() {
        return Professional.get(this.$route.params.uid)
      },
      feedList() {
        return FeedList.get(this.professional.feedList)
      }
    },
    async created() {
      let professional = await Professional.fetchOne(this.$route.params.uid)
      await professional.fetchFeedList()
      this.loading = false
    }
  }
</script>

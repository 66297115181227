<!--
  - Copyright (C)  E-Synaps SAS - 2021 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-navigation-drawer v-model="localDrawer" :value="menuDrawerMobile"
                       :width="$vuetify.breakpoint.mdAndDown ? '90%' : '100%'" :class="$vuetify.breakpoint.mdAndDown ? 'navigation-mobile-container' : ''"
                       :height="$vuetify.breakpoint.lgAndUp ? 'auto' : 'calc(100% - 112px)'"
                       :permanent="$vuetify.breakpoint.lgAndUp" :fixed="$vuetify.breakpoint.mdAndDown"
  >
    <v-card flat class="h-100">
      <v-list-item inactive two-line>
        <v-list-item-avatar tile>
          <font-awesome-icon fixed-width size="2x" :icon="['fad', 'user-md']" class="primary--text" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">{{ professional.full_name }}</v-list-item-title>
          <v-list-item-subtitle class="secondary--text text--darken-1 font-weight-light font-alt">
            Fiche Professionnelle
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-list nav>
        <v-list-item link :to="{name: 'my-contacts'}" exact>
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'chevron-left']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Retour à la liste</v-list-item-title>
        </v-list-item>
        <v-subheader class="pl-2">Général</v-subheader>
        <v-list-item link exact :to="{'name': 'professional-profile'}">
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'user']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Publications</v-list-item-title>
        </v-list-item>
        <!--        <v-list-item :to="{name: 'professional-informations'}" link exact>-->
        <!--          <v-list-item-icon>-->
        <!--            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'map-marker-alt']" class="grey&#45;&#45;text text&#45;&#45;darken-1" />-->
        <!--          </v-list-item-icon>-->
        <!--          <v-list-item-title>Parcours professionel</v-list-item-title>-->
        <!--        </v-list-item>-->
        <template v-if="professional.uid !== $auth.user.sub">
          <v-divider />
          <v-subheader class="pl-2">Actions</v-subheader>
          <v-list-item :disabled="loading.messenger" @click="messengerTo">
            <v-list-item-icon>
              <font-awesome-icon fixed-width size="1x" :icon="['fal', 'comment-dots']" class="grey--text text--darken-1" />
            </v-list-item-icon>
            <v-list-item-title>Envoyer un message</v-list-item-title>
          </v-list-item>
          <v-progress-linear
            v-if="loading.messenger"
            indeterminate
            rounded
            color="secondary"
            height="2"
            class="ma-0"
          />
          <v-list-item link exact class="mt-2" @click="openReferral">
            <v-list-item-icon>
              <font-awesome-icon fixed-width size="1x" :icon="['fal', 'user-md-chat']" class="grey--text text--darken-1" />
            </v-list-item-icon>
            <v-list-item-title>Adresser un patient à ce professionnel</v-list-item-title>
          </v-list-item>
          <v-list-item link exact class="mt-2" @click="openTeamInvite">
            <v-list-item-icon>
              <font-awesome-icon fixed-width size="1x" :icon="['fal', 'users-medical']" class="grey--text text--darken-1" />
            </v-list-item-icon>
            <v-list-item-title>Inviter dans un groupe</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="!loading.isMyContact && professional.uid !== $auth.user.sub" :disabled="loading.requestsAction" link exact @click="isMyContact ? removeContact() : addContact()">
            <v-list-item-icon>
              <font-awesome-icon fixed-width size="1x" :icon="['fal',isMyContact ? 'user-times' : 'user-plus']" class="grey--text text--darken-1" />
            </v-list-item-icon>
            <v-list-item-title>
              {{ isMyContact ? 'Retirer de mes contacts' : 'Ajouter à mes contacts' }}
            </v-list-item-title>
            <v-list-item-action v-if="loading.requestsAction">
              <font-awesome-icon fixed-width size="lg" :icon="['fad', 'spinner-third']" spin
                                 class="primary--text"
              />
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
  import * as Contacts from '@/modules/network/api'
  import * as Conversations from '@/modules/messenger/api/conversations'
  import {Conversation} from '@/modules/messenger/models'
  import {mapState} from 'vuex'

  export default {
    name: 'ProfessionalSideBar',
    props: {
      professional: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        isMyContact: null,
        loading: {
          messenger: false,
          isMyContact: true,
          requestsAction: false
        }
      }
    },
    computed: {
      ...mapState('layout', ['menuDrawerMobile']),
      localDrawer: {
        get() {
          return this.$store.state.layout.menuDrawerMobile
        },
        set(value) {
          this.$store.commit('layout/setDrawerMobile', value)
        }
      }
    },
    async created() {
      Contacts.checkIsMyContact(this.professional.uid)
        .then(response => {
          this.isMyContact = response.data
        })
        .finally(() => {
          this.loading.isMyContact = false
        })

    },
    methods: {
      openTeamInvite() {
        this.$root.$emit('modal:open', {
          modal: () => import('@/modules/teams/components/TeamInviteUserDialog'),
          props: {professionalUid: this.professional.uid}
        })
      },
      async messengerTo() {
        this.loading.messenger = true

        let {data} = await Conversations.getByParticipant(this.professional.uid)
        Conversation.save(data)

        this.loading.messenger = false
        this.$store.commit('messenger/layout/openChatWindow', data.uid)
      },
      openReferral() {
        this.$root.$emit('modal:open', {
          modal: () => import('@/modules/features/referral/views/ReferralForm'),
          props: {
            professionalUid: this.professional.uid
          }
        })
      },
      async addContact() {
        if (await this.$confirm('Envoyer une demande de contact à ' + this.professional.full_name + ' ?')) {
          this.loading.requestsAction = true
          let [err] = await Contacts.sendRequest(this.professional.uid)
          if (err) {
            this.$root.$emit('snackbar:open', {
              snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
              type: 'info',
              title: 'Demande de contact déja envoyée',
              subtitle: 'Une demande de contact a déjà été envoyée à cet utilisateur.'
            })
            return
          }
          this.isMyContact = true
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'success',
            title: 'Demande envoyée',
            subtitle: 'La demande a été envoyée avec succès.'
          })
          this.loading.requestsAction = false
        }
      },
      async removeContact() {
        let confirm = await this.$confirm('Êtes vous sûr de vouloir retirer ' + this.professional.full_name + ' de vos contacts ?')
        if (!confirm) return
        this.loading.requestsAction = true
        await Contacts.remove(this.professional.uid)
        this.isMyContact = false
        setTimeout(() => {
          this.$root.$emit('snackbar:open', {
            snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
            type: 'success',
            title: 'Contact retiré',
            subtitle: 'Contact retiré avec succès.'
          })
        }, 300)
        this.loading.requestsAction = false
      }
    }
  }
</script>

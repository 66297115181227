<!--
  - Copyright (C)  E-Synaps SAS - 2021 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-card flat>
    <v-navigation-drawer width="100%">
      <v-list-item inactive two-line>
        <v-list-item-avatar tile>
          <font-awesome-icon fixed-width size="2x" :icon="['fad', 'user-md']" class="primary--text" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-weight-medium">{{ professional.full_name }}</v-list-item-title>
          <v-list-item-subtitle class="secondary--text text--darken-1 font-weight-light font-alt">
            Fiche Professionnelle
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-list nav>
        <v-list-item link :to="{name: 'my-contacts'}" exact>
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'chevron-left']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Retour à la liste</v-list-item-title>
        </v-list-item>
        <v-subheader class="pl-2">Général</v-subheader>
        <v-list-item link exact :to="{'name': 'professional-profile'}">
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'user']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Publications</v-list-item-title>
        </v-list-item>
        <!--        <v-list-item :to="{name: 'professional-informations'}" link exact>-->
        <!--          <v-list-item-icon>-->
        <!--            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'map-marker-alt']" class="grey&#45;&#45;text text&#45;&#45;darken-1" />-->
        <!--          </v-list-item-icon>-->
        <!--          <v-list-item-title>Parcours professionel</v-list-item-title>-->
        <!--        </v-list-item>-->
        <v-divider />
        <v-subheader class="pl-2">Actions</v-subheader>
        <v-list-item link exact>
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'calendar-check']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Prise de rendez-vous</v-list-item-title>
        </v-list-item>
        <v-list-item link exact class="mt-2">
          <v-list-item-icon>
            <font-awesome-icon fixed-width size="1x" :icon="['fal', 'users-medical']" class="grey--text text--darken-1" />
          </v-list-item-icon>
          <v-list-item-title>Ajouter à mon equipe soignante</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>

  export default {
    name: 'ProfessionalSideBar',
    props: {
      professional: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
      }
    },
    methods: {

    }
  }
</script>
